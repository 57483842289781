import React from 'react'
import meImage from '../../media/me.jpg'

import { Container, ProfilePicture, Heading } from './styles'

export const Bio = () => (
  <Container data-testid='bio'>
    <ProfilePicture src={meImage} alt='Jonathan Davis' />

    <Heading>
      Leadership,
      <br />
      Web and Mobile Apps
    </Heading>

    <p>
      I love thoughts and ideas, enjoy conversation and people, and am driven by
      learning and creativity.
    </p>

    <p>
      Professionally, I thrive in technology and rethinking the way we live and
      connect.
    </p>
  </Container>
)

export default Bio
