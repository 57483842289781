import React, { useEffect } from 'react'
import { Container, Heading } from './styles'

const Ideals = () => {
  useEffect(() => {
    document.title = `Ideals | Jonathan Davis`
  })

  return (
    <Container data-testid='ideals'>
      <Heading>Ideals</Heading>

      <h2>Peer Review</h2>

      <p>
        Great authors have editors, and great code has reviewers. Either by pair
        programming or code review, get a second set of eyes on the code.
      </p>

      <h2>All Code is Tested</h2>

      <p>
        Be confident, quick, and sustainable in your work. Your time is
        valuable, reduce maintenance costs and maintain your rate of delivering
        value by automating your quality checks.
      </p>

      <p>
        <strong>Tests should focus on API and user interaction.</strong>{' '}
        Internal implementation should not be tested.
      </p>

      <ul>
        <li>Anything a user can do, write an end-to-end (e2e) test for it.</li>
        <li>
          Business logic, computation, and data transforms, write a unit test.
        </li>
        <li>Use a code linter and static type checking while you code.</li>
      </ul>

      <p>The guiding principle for testing is:</p>

      <em>
        The more your tests resemble the way your software is used, the more
        confidence they can give you.
      </em>

      <h2>Ownership of Quality</h2>

      <p>
        Be accountable for what you build. Be ever ready to give report on the
        quality and performance of your work. Build trust by proactively fixing
        problems when they occur.
      </p>

      <p>Be automatically notified of:</p>

      <ul>
        <li>an outage</li>
        <li>a runtime error</li>
        <li>a degredation performance</li>
      </ul>

      <h2>Support the World's Diversity</h2>

      <p>
        Make your work accessible to all, including those depending on{' '}
        <a href='https://www.a11yproject.com'>accessibility (a11y)</a>. Become
        comfortable with{' '}
        <a href='https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA'>
          ARIA
        </a>
        , using screen readers, scaling fonts, and other assistive technology
        and actively use them as part of your development.
      </p>

      <p>
        The world is full of diverse cultures and languages, don't let that be a
        barrier to future growth -
        <a href='https://en.wikipedia.org/wiki/Internationalization_and_localization'>
          internationalize (i18n) and localize (l10n)
        </a>
        . Support a user's native experience in text, numbers, and presentation.
      </p>

      <h2>No Code Can Serve Two Masters</h2>

      <p>
        Code duplication is preferable to the wrong abstraction. As complexity
        increases, so does the cost of maintenance and readability. Keep code
        simple. Duplicate first and only after a pattern worth the cost of
        abstraction emerges should it be refactored. The code you are trying to
        abstract may look similar, but could very well be headed in different
        directions.
      </p>

      <p>
        The best way to prevent coupling is to keep code co-located - things
        that change together should be located as close together as possible.
        Only bring code "up" and into a shared place when needed. For example,
        don't create a new component at the top level of a project if it only
        has one usage. Place it with the consuming component.
      </p>

      <h2>When in Rome...</h2>

      <p>
        Build to the best practices, patterns, and standards of the community
        and/or platform.
      </p>

      <p>
        For Apple platforms, this means following the{' '}
        <a href='https://developer.apple.com/design/human-interface-guidelines/guidelines/overview'>
          Human Interface Guidelines
        </a>{' '}
        and for Android platforms following{' '}
        <a href='https://developer.android.com/develop/ui/views/theming/look-and-feel'>
          Material Design
        </a>
        .
      </p>

      <p>
        For coding languages, this means using the built-in libraries and common
        conventions of the community.
      </p>

      <p>
        The best measure is if someone from the community or platform can look
        at your code and feel at home.
      </p>

      <h2>Buy vs Build</h2>

      <p>
        Keep your secret sauce in-house, focus on your bread and butter (trade
        secrets and core business logic). If you're not willing to maintain it,
        don't build it. Use and/or pay for a third-party service or library
        instead.
      </p>

      <h2>No Assembly Required</h2>

      <p>
        If you find yourself writing a lot of logic and data transformson
        client, consider moving the work back to the server.
      </p>

      <h2>Be Resilient</h2>

      <p>
        System should continue operating even when one or more components or
        dependencies fail or malfunction. Graceful degradation or redundancy are
        useful strategies. If only the Internet, or a quality connection, could
        always be and there were no earthquakes, hurricanes, or other natural
        disasters. Always give the best service or user experience possible.
      </p>

      <h2>Documentation</h2>

      <p>
        README is the entry point for each project. It contains the purpose of
        the project, instructions for setting up a development environment, how
        to run tests, and how to ship work to production.
      </p>

      <p>
        All APIs are to be documented as if to be shared as a public API
        external to the company.
      </p>
    </Container>
  )
}

export default Ideals
